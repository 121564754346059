<template>
  <ui-component-modal
    modalTitle=""
    :isSaving="isSaving"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteToDo"
    :showModal="showModal"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :buttonLabelConfirm="'Delete'"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <section v-if="!isDeletingError && !isDeletingSuccess" class="section">
        <div class="container">
          <div class="title">Are you sure?</div>
          <div>This can't be undone.</div>
        </div>
      </section>
    </template>
  </ui-component-modal>
</template>

<script>
import todoProvider from '@/providers/todo'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
    todo: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isDeletingError: false,
      isDeletingSuccess: false,
      isSaving: false,
      mToDo: this.todo,
    }
  },

  methods: {
    deleteToDo() {
      let self = this
      self.isSaving = true

      todoProvider.methods
        .deleteToDo(self.mToDo.Id)
        .then((response) => {
          if (response.status === 204) {
            self.$emit('todoDeleted', self.mToDo)

            self.isDeletingSuccess = true

            setTimeout(() => {
              self.onClickCancel()
            }, 1000)
          }
        })
        .catch((error) => {
          //console.log(error.response)
          self.isDeletingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>
